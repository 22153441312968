<template>
  <div>
    <div class="row audit-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.COMPLIANCE')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              v-if="canRemove"
              :disabled="auditTable.selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
            </div>
            <v-data-table
              v-model="auditTable.selected"
              :headers="headers"
              :items="auditTable.rules"
              :single-select="auditTable.singleSelect"
              show-select
              item-key="id"
              class="elevation-1"
              ref="auditTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------add audit rule modal------------- -->
            <b-modal
              id="addAuditRule"
              ref="addAuditRule"
              :title="$t('COMMON.NEW')"
              size="lg"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label>{{ $t("AUDIT.AUDIT_TYPE") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-checkbox
                      size="lg"
                      v-model="addForm.auditType.incoming"
                      :value="1"
                    >
                      {{ $t("AUDIT.INCOMING") }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      size="lg"
                      v-model="addForm.auditType.outgoing"
                      :value="1"
                    >
                      {{ $t("AUDIT.OUTGOING") }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label for="entry">{{
                      $t("AUDIT.ENTRY")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      id="entry"
                      v-model="$v.addForm.auditEntry.$model"
                      :state="validateState('auditEntry')"
                      :placeholder="$t('AUDIT.EMAIL_ADDRESS_DOMAIN')"
                      aria-describedby="input-entry-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-entry-feedback">
                      {{ $t("VALIDATION.EMAIL_DOMAIN_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label for="auditEmail">{{
                      $t("AUDIT.AUDIT_EMAIL")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addForm.auditEmail.selected"
                      value-field="value"
                      text-field="text"
                      :options="addForm.auditEmail.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="createItem()">
                  {{ $t("COMMON.ADD") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ----------delete confirm modal------------- -->
            <!-- ----------- delete one alias confirm modal -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem.auditEntry }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete aliases confirm modal -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required, email, or, helpers } from "vuelidate/lib/validators";

const domainRegex = helpers.regex(
  "domain", 
  /^[a-z0-9+-]+(\.[a-z0-9+-]+)*(\.[a-z]{2,11})$/
);

export default {
  mixins: [validationMixin],
  name: "audit",
  components: {
    KTPortlet,
  },
  data: () => ({
    search: "",
    auditTable: {
      singleSelect: false,
      rules: [],
      selected: [],
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    addForm: {
      auditEntry: "",
      auditType: {
        incoming: 0,
        outgoing: 0,
      },
      auditEmail: {
        selected: "",
        data: [],
      },
      domain: "",
    },
    delItem: "",
  }),
  computed: {
    headers: function () {
      return [
        { text: this.$t("AUDIT.ENTRY"), value: "audit_entity" },
        { text: this.$t("AUDIT.AUDIT_EMAIL"), value: "audit_email" },
        { text: this.$t("AUDIT.INCOMING"), value: "incoming" },
        { text: this.$t("AUDIT.OUTGOING"), value: "outgoing" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    canAdd: function () {
      return this.hasPermission("aliases", 5);
    },
    canRemove: function () {
      return this.hasPermission("aliases", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllAuditDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.getAuditRequest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addForm: {
      auditEntry: {
        required,
        isValid: or(email, domainRegex),
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.COMPLIANCE") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    addformReset() {
      this.addForm.auditEntry = "";
      this.addForm.domain = "";
      this.addForm.auditEmail.selected = "";
      this.addForm.auditEmail.data = [];
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.addformReset();
      this.$refs["addAuditRule"].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ---------read item---------- */
    getAuditRequest(domain) {
      this.auditTable.rules = [];
      this.auditTable.selected = [];
      postRequest({
        action: "getAuditEntries",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.auditTable.rules = res.returndata;
          for (let i = 0; i < this.auditTable.rules.length; i++) {
            if (this.auditTable.rules[i].incoming == 0) {
              this.auditTable.rules[i].incoming = this.$t("COMMON.NO");
            } else {
              this.auditTable.rules[i].incoming = this.$t("COMMON.YES");
            }
            if (this.auditTable.rules[i].outgoing == 0) {
              this.auditTable.rules[i].outgoing = this.$t("COMMON.NO");
            } else {
              this.auditTable.rules[i].outgoing = this.$t("COMMON.YES");
            }
          }
        } else {
          this.auditTable.rules = [];
        }
      });
    },
    getAuditForSelectedDomain(selectedDomain) {
      this.getAuditRequest(selectedDomain);
    },

    /* ------create item---- */
    openCreateModal() {
      if (
        !this.selectDomain.allDomains.length ||
        this.selectDomain.allDomains[0].id == null
      ){
        this.makeToastVariant("danger", this.$t('AUDIT.NO_AUDIT_DOMAIN') );
      } else {
        this.addForm.domain = this.selectDomain.selectedDomain.domain;
        postRequest({
          action: "getAllUsersDomain",
          token: localStorage.id_token,
          domain: this.addForm.domain,
          offset: 0,
          limit: 0,
          sorting: 0,
          slicing: 0,
          getOTPAndToken: 0,
          showQuota: 0,
        }).then((res) => {
          if (res.returncode) {
            this.addForm.auditEmail.selected = res.returndata[0].email;
            res.returndata.forEach((el) => {
              this.addForm.auditEmail.data.push({
                value: el.email,
                text: el.email,
                domain: el.domain,
                username: el.username,
              });
            });
            this.$refs["addAuditRule"].show();
          } else {
            //this.makeToastVariant("danger", res.returndata );
            this.makeToastVariant("danger", this.$t('AUDIT.NO_AUDIT_EMAIL') );
          }
        });
      }
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.auditEntry.$anyError) {
        return;
      }
      let auditEmail = this.addForm.auditEmail.selected.split("@");
      let auditEntry = this.addForm.auditEntry;
      postRequest({
        action: "addAuditEntry",
        token: localStorage.id_token,
        user: auditEmail[0],
        domain: auditEmail[1],
        entry: auditEntry,
        incoming: this.addForm.auditType.incoming,
        outgoing: this.addForm.auditType.outgoing,
      }).then((res) => {
        this.modalClose();
        if (res.returncode) {
          this.getAuditRequest(this.selectDomain.selectedDomain.domain);
          this.makeToastVariant("success", res.returndata + '. ' + this.$t('COMMON.WAIT_MESSAGE'));
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.addformReset();
      });
    },
    /* ------create item end---- */
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },

    deleteRequest(auditItem) {
      postRequest({
        action: "removeAuditEntry",
        token: localStorage.id_token,
        entry: auditItem.audit_entity,
        user: auditItem.audit_user,
        domain: auditItem.audit_domain,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata+ '. ' + this.$t('COMMON.WAIT_MESSAGE'));
          this.getAuditForSelectedDomain(this.selectDomain.selectedDomain.domain);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item);
    },
    deleteItems() {
      if (this.auditTable.selected.length > 0) {
        this.auditTable.selected.forEach((el) => {
          this.deleteRequest(el.id);
        });
      }
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        solid: true,
      });
    },
     /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getAuditForSelectedDomain(newValue.domain);
    },
  },
};
</script>
